import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../common/models/loadingStatus";
import { EnvironmentDetails } from "../models/environment";
import { getEnvironmentDetailsAsync } from "../services/environmentService";

type EnvironmentState = {
  environmentDetails: EnvironmentDetails | null;
  status: LoadingStatus;
  error: string;
};

const initialState: EnvironmentState = {
  environmentDetails: null,
  status: LoadingStatus.idle,
  error: "",
};

export const getEnvironmentDetails = createAsyncThunk(
  "environment/getEnvironmentDetails",
  async (_, { rejectWithValue }) => {
    try {
      var response = await getEnvironmentDetailsAsync();

      return response.data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

const environmentSlice = createSlice({
  name: "environment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEnvironmentDetails.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getEnvironmentDetails.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        environmentDetails: action.payload.data,
      };
    });

    builder.addCase(getEnvironmentDetails.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export default environmentSlice.reducer;
