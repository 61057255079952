import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../../common/models/apiResponseMeta";
import axios from "../../../utils/axios";

export type ResetPasswordResponse = {
  meta: ApiResponseMeta;
  data: {
    emailSent: boolean;
  };
};

type ResetpasswordRequest = {
  email?: string;
  userId?: string;
};

type DeleteAccountRequest = {
  userId: string;
};

async function resetPassword({
  email,
  userId,
}: ResetpasswordRequest): Promise<AxiosPromise<ResetPasswordResponse>> {
  const body = {
    email: email,
    userId: userId,
  };

  return await axios.post(`accounts/v1/reset-password`, body);
}

async function deleteAccount({
  userId,
}: DeleteAccountRequest): Promise<AxiosPromise> {
  const body = {
    userId: userId,
  };

  return await axios.post(`accounts/v1/request-deletion`, body);
}

export { resetPassword, deleteAccount };
