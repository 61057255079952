import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../../common/models/apiResponseMeta";
import { UserRoleCompliance } from "../models/accreditationUser";
import axios from "../../../utils/axios";
import { QueryRoleComplianceRequest } from "../slices/accreditationUserSlice";

type QueryRoleComplianceResponse = {
  meta: ApiResponseMeta;
  data: UserRoleCompliance[];
};

export async function queryRoleCompliance(
  request: QueryRoleComplianceRequest
): Promise<AxiosPromise<QueryRoleComplianceResponse>> {
  return await axios.post(`accreditations/users/v1/query-compliance`, request);
}
