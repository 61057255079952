import React, { Suspense, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Outlet, useLocation, useParams } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import { InnerNavigationItem, SidebarItemsType } from "../../types/sidebar";
import {
  ButtonProps,
  PageHeaderContext,
} from "../../contexts/PageHeaderContext";
import UserGuard from "../guards/UserGuard";
import { UserOrganisationRole } from "../../features/common/models/user";
import GlobalStyle from "../GlobalStyle";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import Loader from "../Loader";
import PageWrapper from "../PageWrapper";
import { Worker } from "../../features/compliance/models/worker";
import { navSections } from "../../sidebarItems/navSections";
import { Group } from "../../features/compliance/models/group";
import { AccreditationSpec } from "../../features/common/models/accreditationSpec";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

interface DashboardType {
  children?: React.ReactNode;
}

type PageDetails = {
  innerNavigationItems?: InnerNavigationItem[];
  title?: string;
  hasBackButton?: boolean;
  backButtonTo?: string;
};

const VisiblyOSLayout: React.FC<DashboardType> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [pageDetail, setPageDetail] = useState<PageDetails>();
  const { groupId, workerId, accreditationSpecId } = useParams();
  const [group, setGroup] = useState<Group>();
  const [worker, setWorker] = useState<Worker>();
  const [accreditationSpec, setAccreditationSpec] =
    useState<AccreditationSpec>();
  const [buttons, setButtons] = useState<ButtonProps[]>();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const location = useLocation();

  const getChildNavSection = useCallback(
    (path: string, navSection: SidebarItemsType): SidebarItemsType => {
      var childNavSection = navSection.children?.filter((el) => {
        if (el.innerNavigationItems) {
          return (
            el.innerNavigationItems?.filter((e) => {
              var f = e.to({
                groupId: groupId,
                workerId: workerId,
                accreditationSpecId: accreditationSpecId,
              });
              return path.includes(f?.toLowerCase());
            }).length > 0
          );
        }
        return false;
      });
      if (childNavSection && childNavSection.length > 0) {
        return getChildNavSection(path, childNavSection[0]);
      }
      return navSection;
    },
    [groupId, workerId, accreditationSpecId]
  );

  useEffect(() => {
    if (
      (groupId && group?.id !== groupId) ||
      (workerId && worker?.id !== workerId) ||
      (accreditationSpecId &&
        accreditationSpec?.id !== Number(accreditationSpecId))
    ) {
      setPageDetail((p) => ({
        ...p,
        title: undefined,
      }));
      return;
    }

    const path = location.pathname?.toLowerCase();
    let navSection = navSections
      .filter((el) =>
        el.pages.find((el) => path.includes(el.href?.toLowerCase()))
      )[0]
      .pages.find((el) => path.includes(el.href?.toLowerCase()));

    if (navSection) {
      let title = navSection!.pageTitle();
      if (navSection.href !== path && navSection.children?.length > 0) {
        navSection = getChildNavSection(path, navSection);

        switch (navSection.type) {
          case "group":
            title = navSection!.pageTitle(group?.name);
            break;
          case "worker":
            title = navSection!.pageTitle(
              `${worker?.firstNames} ${worker?.lastName ?? ""}`
            );
            break;
          case "accreditationSpec":
            title = navSection!.pageTitle(`${accreditationSpec?.name ?? ""}`);
            break;
          default:
            title = navSection!.pageTitle();
            break;
        }
      }

      setPageDetail({
        innerNavigationItems: navSection.innerNavigationItems,
        title: title,
        hasBackButton: navSection.hasBackButton,
        backButtonTo: navSection.backButtonTo
          ? navSection.backButtonTo!({
              groupId: groupId,
              workerId: workerId,
              accreditationSpecId: accreditationSpecId,
            })
          : undefined,
      });
    }
  }, [
    location,
    group,
    worker,
    groupId,
    workerId,
    getChildNavSection,
    accreditationSpec,
    accreditationSpecId,
  ]);

  return (
    <PageHeaderContext.Provider
      value={{
        group: group,
        setGroup: setGroup,
        worker: worker,
        setWorker: setWorker,
        buttons: buttons,
        setButtons: setButtons,
        accreditationSpec: accreditationSpec,
        setAccreditationSpec: setAccreditationSpec,
      }}
    >
      <Root>
        <UserGuard
          allowedRoles={[
            UserOrganisationRole.owner,
            UserOrganisationRole.admin,
          ]}
        >
          <CssBaseline />
          <GlobalStyle />
          <Drawer>
            <Box sx={{ display: { xs: "block", lg: "none" } }}>
              <Sidebar
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                items={navSections}
              />
            </Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Sidebar
                PaperProps={{ style: { width: drawerWidth } }}
                items={navSections}
              />
            </Box>
          </Drawer>
          <AppContent>
            <Navbar onDrawerToggle={handleDrawerToggle} />
            <Suspense fallback={<Loader />}>
              <MainContent p={isLgUp ? 12 : 5}>
                <PageWrapper
                  title={pageDetail?.title}
                  innerNavigationItems={pageDetail?.innerNavigationItems}
                  hasBackButton={pageDetail?.hasBackButton}
                  backButtonTo={pageDetail?.backButtonTo}
                  buttons={buttons}
                >
                  {children}
                </PageWrapper>
                <Outlet />
              </MainContent>
            </Suspense>
          </AppContent>
        </UserGuard>
      </Root>
    </PageHeaderContext.Provider>
  );
};

export default VisiblyOSLayout;
