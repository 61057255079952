import { Table } from "react-feather";
import { SidebarItemsType } from "../../types/sidebar";

export const librariesSection = [
  {
    href: "/libraries/accreditations",
    icon: Table,
    pageTitle: () => "Accreditation library",
    title: "Accreditations",
    children: [
      {
        pageTitle: (accreditationSpecName: string) =>
          `${accreditationSpecName ?? ""}`,
        hasBackButton: true,
        backButtonTo: (_) => `/libraries/accreditations`,
        type: "accreditationSpec",
        innerNavigationItems: [
          {
            title: "Details",
            to: (_) =>
              `/libraries/accreditations/${_.accreditationSpecId}/details`,
          },
          {
            title: "Settings",
            to: (_) => ``,
            disabled: true,
          },
        ],
      },
    ],
  },
] as SidebarItemsType[];
