import { useCurrentOrg } from "../../hooks/userHooks";
import NavigateToUserHome from "../NavigateToUserHome";

interface CanViewCapabilityRouteGuardProps {
  children: JSX.Element;
  capability: string;
}

function CanViewCapabilityRouteGuard({
  children,
  capability,
}: CanViewCapabilityRouteGuardProps) {
  const currentOrg = useCurrentOrg();

  if (currentOrg?.capabilities?.find((e) => e === capability) === undefined) {
    return <NavigateToUserHome />;
  }

  return <>{children}</>;
}

export default CanViewCapabilityRouteGuard;
