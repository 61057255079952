import { AxiosPromise, AxiosResponse } from "axios";
import { ApiResponseMeta } from "../models/apiResponseMeta";
import { Team } from "../models/teams";
import axios from "../../../utils/axios";

export type GetTeamsRequest = {
  orgId: string;
  name?: string | null;
  capabilities?: string[];
};

type GetTeamsResponse = {
  data: Team[];
  meta: ApiResponseMeta;
};

async function getTeams(
  request: GetTeamsRequest
): Promise<AxiosResponse<GetTeamsResponse>> {
  return await axios.post(`/teams/v1/query`, request);
}

export type UpdateTeamRequest = {
  id: string;
  name: string;
  description?: string;
  capabilities?: string[];
};

type UpdateTeamResponse = {
  data: Team;
  meta: ApiResponseMeta;
};

interface CreateTeamRequest {
  orgId: string;
  name: string;
  description?: string;
  capabilities?: string[];
}

async function updateTeam(
  request: UpdateTeamRequest
): Promise<AxiosResponse<UpdateTeamResponse>> {
  const body = {
    name: request.name,
    description: request.description,
    capabilities: request.capabilities,
  };
  return await axios.put(`/teams/v1/${request.id}`, body);
}

type CreateTeamResponse = {
  data: Team;
  meta: ApiResponseMeta;
};

async function createTeam(
  request: CreateTeamRequest
): Promise<AxiosPromise<CreateTeamResponse>> {
  const body = {
    orgId: request.orgId,
    description: request.description,
    name: request.name,
    capabilities: request.capabilities,
  };

  return await axios.post(`/teams/v1`, body);
}

type GetTeamResponse = {
  data: Team;
  meta: ApiResponseMeta;
};

async function getTeamById(
  id: string
): Promise<AxiosResponse<GetTeamResponse>> {
  return await axios.get(`/teams/v1/${id}`);
}

export type AddTeamMembersRequest = {
  userIds: string[];
  teamId: string;
};

async function addTeamMembers(
  request: AddTeamMembersRequest
): Promise<AxiosPromise> {
  const body = {
    userIds: request.userIds,
  };
  return await axios.post(`/teams/v1/${request.teamId}/members/bulk-add`, body);
}

export type RemoveTeamMembersRequest = {
  userIds: string[];
  teamId: string;
};

async function removeTeamMembers(
  request: RemoveTeamMembersRequest
): Promise<AxiosPromise> {
  const body = {
    userIds: request.userIds,
  };

  return await axios.post(
    `/teams/v1/${request.teamId}/members/bulk-delete`,
    body
  );
}

export {
  getTeams,
  updateTeam,
  getTeamById,
  addTeamMembers,
  removeTeamMembers,
  createTeam,
};
