import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../common/models/loadingStatus";
import * as accreditationService from "../services/accreditationService";
import {
  AccreditationWithProofs,
  Accreditation,
} from "../models/accreditation";
import { AxiosError } from "axios";
import { uploadFileToFileStorage } from "../../common/services/s3ClientStorageService";

type ImportAccreditationCsvRequest = {
  orgId: string;
  file: File;
};

export const importAccreditationCsv = createAsyncThunk(
  "accreditation/importAccreditationCsv",
  async (request: ImportAccreditationCsvRequest, { rejectWithValue }) => {
    const importId = nanoid();

    try {
      await uploadFileToFileStorage(
        `public/${request.orgId}/uploads/${importId}.csv`,
        request.file,
        "text/csv"
      );

      await accreditationService.importAccreditations({
        orgId: request.orgId,
        csvImportId: importId,
      });
    } catch (err: any) {
      let error = err?.message ?? "An error occurred";
      if (err instanceof AxiosError && err?.response?.data.meta.errorMessage) {
        error = err.response.data.meta.errorMessage;
      }
      return rejectWithValue({
        error: error,
      });
    }
  }
);

export type QueryAccreditationsRequest = {
  orgId: string;
  statuses?: string[];
  userId?: string;
};

export const queryAccreditations = createAsyncThunk(
  "accreditation/queryAccreditations",
  async (request: QueryAccreditationsRequest, { rejectWithValue }) => {
    try {
      return (await accreditationService.queryAccreditations(request)).data;
    } catch (err: any) {
      let error = err?.message ?? "An error occurred";
      if (err instanceof AxiosError && err?.response?.data.meta.errorMessage) {
        error = err.response.data.meta.errorMessage;
      }
      return rejectWithValue({
        error: error,
      });
    }
  }
);

export const getAccreditation = createAsyncThunk(
  "accreditation/getAccreditation",
  async (request: { id: string }, { rejectWithValue }) => {
    try {
      return (await accreditationService.getAccreditation(request.id)).data;
    } catch (err: any) {
      let error = err?.message ?? "An error occurred";
      if (err instanceof AxiosError && err?.response?.data.meta.errorMessage) {
        error = err.response.data.meta.errorMessage;
      }
      return rejectWithValue({
        error: error,
      });
    }
  }
);

type SubmitProofsRequest = {
  orgId: string;
  proofs: File[];
  accreditationId: string;
};

export const submitProofs = createAsyncThunk(
  "accreditation/submitProofs",
  async (request: SubmitProofsRequest, { rejectWithValue }) => {
    try {
      const proofs = await Promise.all(
        request.proofs.map(async (proof) => {
          const mediaKey = `accreditations/${request.orgId}/${nanoid()}_${
            proof.name
          }`;
          await uploadFileToFileStorage(mediaKey, proof, proof.type);

          return {
            type:
              proof.type === "application/pdf"
                ? "pdf"
                : ("photo" as "pdf" | "photo"),
            createdAt: new Date().toISOString(),
            mediaKey: mediaKey,
          };
        })
      );

      await accreditationService.submitProofs(request.accreditationId, {
        proofs: proofs,
        orgId: request.orgId,
      });

      return {
        accreditationId: request.accreditationId,
        proofs: proofs,
      };
    } catch (err: any) {
      let error = err?.message ?? "An error occurred";
      if (err instanceof AxiosError && err?.response?.data.meta) {
        const errorCode = err.response.data.meta.errorCode;
        if (errorCode === "404-COMMON-EntityNotFound") {
          error =
            "Unable to add proofs to accreditation. Please reload and try again";
        }
      }
      return rejectWithValue({
        error: error,
      });
    }
  }
);

type AccreditationState = {
  status: LoadingStatus;
  submitProofStatus: LoadingStatus;
  getAccreditationStatus: LoadingStatus;
  accreditations: Accreditation[];
  accreditation?: AccreditationWithProofs;
  error: string | null;
};

const initialState: AccreditationState = {
  status: LoadingStatus.idle,
  submitProofStatus: LoadingStatus.idle,
  getAccreditationStatus: LoadingStatus.idle,
  accreditations: [],
  error: null,
};

const accreditationSlice = createSlice({
  name: "accreditation",
  initialState,
  reducers: {
    cleanState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(importAccreditationCsv.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(importAccreditationCsv.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
      };
    });
    builder.addCase(importAccreditationCsv.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(queryAccreditations.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(queryAccreditations.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        accreditations: action.payload.data,
      };
    });
    builder.addCase(queryAccreditations.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(submitProofs.pending, (state, action) => {
      return { ...state, submitProofStatus: LoadingStatus.loading };
    });
    builder.addCase(submitProofs.fulfilled, (state, action) => {
      return {
        ...state,
        submitProofStatus: LoadingStatus.succeeded,
      };
    });
    builder.addCase(submitProofs.rejected, (state, action) => {
      return {
        ...state,
        submitProofStatus: LoadingStatus.failed,
      };
    });
    builder.addCase(getAccreditation.pending, (state, action) => {
      return { ...state, getAccreditationStatus: LoadingStatus.loading };
    });
    builder.addCase(getAccreditation.fulfilled, (state, action) => {
      return {
        ...state,
        getAccreditationStatus: LoadingStatus.succeeded,
        accreditation: action.payload.data,
      };
    });
    builder.addCase(getAccreditation.rejected, (state, action) => {
      return {
        ...state,
        getAccreditationStatus: LoadingStatus.failed,
      };
    });
  },
});

export const { cleanState } = accreditationSlice.actions;

export default accreditationSlice.reducer;
