import { SidebarItemsType } from "../../types/sidebar";
import { groupsItem } from "./groupsItem";
import { workforceItem } from "./workforceItem";

export const complianceSection = [
  {
    ...workforceItem,
  },
  {
    ...groupsItem,
  },
] as SidebarItemsType[];
