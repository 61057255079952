import { lazy } from "react";
import BasicLayout from "../layouts/Basic";
import { RouteObject } from "react-router-dom";
import AuthGuard from "../components/guards/AuthGuard";
import NavigateToUserHome from "../components/NavigateToUserHome";
import { unauthenticatedRoutes } from "./unauthenticatedRoutes";
import { organisationRoutes } from "./organisationRoutes";
import { checkInsRoutes } from "./checkInsRoutes";
import { internalAdminRoutes } from "./internalAdminRoutes";
import { externalRoutes } from "./externalRoutes";
import { workforceRoutes } from "./workforceRoutes";
import { groupsRoutes } from "./groupsRoutes";
import { experimentGroupRoutes } from "./experiment/groupRoutes";
import { experimentDirectWorkforceRoutes } from "./experiment/directWorkforceRoutes";
import { experimentLibraryRoutes } from "./experiment/libraryRoutes";
import { librariesRoutes } from "./librariesRoutes";

const Eula = lazy(() => import("../features/account/pages/eula"));

const DownloadApp = lazy(() => import("../features/account/pages/downloadApp"));

const routes = [
  ...unauthenticatedRoutes,
  ...organisationRoutes,
  ...checkInsRoutes,
  ...internalAdminRoutes,
  ...externalRoutes,
  ...workforceRoutes,
  ...groupsRoutes,
  ...librariesRoutes,
  ...experimentGroupRoutes,
  ...experimentDirectWorkforceRoutes,
  ...experimentLibraryRoutes,
  {
    path: "download-app",
    element: (
      <BasicLayout>
        <DownloadApp />
      </BasicLayout>
    ),
  },
  {
    path: "eula",
    element: (
      <AuthGuard>
        <BasicLayout>
          <Eula />
        </BasicLayout>
      </AuthGuard>
    ),
  },
  {
    path: "*",
    element: <NavigateToUserHome />,
  },
] as RouteObject[];

export type { RouteObject };

export default routes;
