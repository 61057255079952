import { lazy } from "react";
import LogoFormLayout from "../layouts/LogoForm";
import { RouteObject } from "react-router-dom";

const ChooseOrganisation = lazy(
  () => import("../features/organisation/pages/chooseOrganisation")
);

export const organisationRoutes = [
  {
    path: "organisation",
    element: <LogoFormLayout />,
    children: [
      {
        path: "choose",
        element: <ChooseOrganisation />,
      },
    ],
  },
] as RouteObject[];
