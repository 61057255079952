import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
  createAction,
  AnyAction,
} from "@reduxjs/toolkit";
import teamsReducer from "../features/common/slices/teamsSlice";
import userReducer from "../features/common/slices/userSlice";
import publicInviteReducer from "../features/account/slices/publicInviteSlice";
import userOrganisationMemberReducer from "../features/common/slices/userOrganisationMemberSlice";
import usersOrganisationMembersReducer from "../features/common/slices/usersOrganisationMembersSlice";
import industriesReducer from "../features/internalAdmin/slices/industriesSlice";
import activitiesReducer from "../features/checkIns/slices/activitySlice";
import auditLogsReducer from "../features/checkIns/slices/auditLogSlice";
import checkInStatsReducer from "../features/checkIns/slices/checkInStatsSlice";
import environmentReducer from "../features/account/slices/environmentSlice";
import accreditationUserReducer from "../features/compliance/slices/accreditationUserSlice";
import organisationMembersReducer from "../features/compliance/slices/organisationMembersSlice";
import accreditationReducer from "../features/compliance/slices/accreditationSlice";
import rolesReducer from "../features/compliance/features/groups/slices/rolesSlice";
import groupsReducer from "../features/compliance/features/groups/slices/groupsSlice";
import groupRolesReducer from "../features/compliance/features/groups/slices/groupRolesSlice";
import accreditationRoleReducer from "../features/compliance/slices/accreditationRolesSlice";
import accreditationSpecsReducer from "../features/common/slices/accreditationSpecsSlice";

const rootReducer = combineReducers({
  userOrganisationMember: userOrganisationMemberReducer,
  usersOrganisationMembers: usersOrganisationMembersReducer,
  publicInvite: publicInviteReducer,
  teams: teamsReducer,
  user: userReducer,
  industries: industriesReducer,
  activities: activitiesReducer,
  auditLogs: auditLogsReducer,
  checkInStats: checkInStatsReducer,
  environment: environmentReducer,
  accreditationUser: accreditationUserReducer,
  organisationMembers: organisationMembersReducer,
  accreditation: accreditationReducer,
  roles: rolesReducer,
  groups: groupsReducer,
  groupRoles: groupRolesReducer,
  accreditationRoles: accreditationRoleReducer,
  accreditationSpecs: accreditationSpecsReducer,
});

const resettableRootReducer = (
  state: ReturnType<typeof rootReducer> | undefined,
  action: AnyAction
): ReturnType<typeof rootReducer> => {
  if (action.type === resetStore.type) {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

export const resetStore = createAction("store/reset");

export const store = configureStore({
  reducer: resettableRootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
