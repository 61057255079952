import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../common/models/loadingStatus";
import { PublicInvite } from "../models/publicInvite";
import * as publicInviteService from "../services/invitesService";

type PublicInviteState = {
  publicInvite: PublicInvite | null;
  status: LoadingStatus;
  error: string;
};

const initialState: PublicInviteState = {
  publicInvite: null,
  status: LoadingStatus.idle,
  error: "",
};

export const getPublicInvite = createAsyncThunk(
  "publicInvite/setInvite",
  async (id: string, { rejectWithValue }) => {
    try {
      return (await publicInviteService.getPublicInvite(id)).data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

const publicInviteSlice = createSlice({
  name: "publicInvite",
  initialState,
  reducers: {
    clearPublicInvite: (state) => {
      return {
        ...state,
        publicInvite: null,
        status: LoadingStatus.idle,
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPublicInvite.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getPublicInvite.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        publicInvite: action.payload.data,
      };
    });

    builder.addCase(getPublicInvite.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export const { clearPublicInvite } = publicInviteSlice.actions;
export default publicInviteSlice.reducer;
