import React from "react";
import { Navigate } from "react-router-dom";
import { useCurrentOrg, useCurrentUser } from "../../hooks/userHooks";
import useAuth from "../../hooks/useAuth";
import { UserOrganisationRole } from "../../features/common/models/user";
import useAppSelector from "../../hooks/useAppSelector";

interface UserGuardType {
  children: React.ReactNode;
  allowedRoles?: string[];
}

function UserGuard({ children, allowedRoles }: UserGuardType) {
  const { isAuthenticated } = useAuth();
  const currentOrg = useCurrentOrg();
  const currentUser = useCurrentUser();
  const environmentDetails = useAppSelector(
    (state) => state.environment.environmentDetails
  );

  if (!isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }

  if (
    environmentDetails?.eula?.url &&
    environmentDetails?.eula?.version !==
      currentUser?.additionalInfo?.acceptedEulaVersion
  ) {
    return <Navigate to="/eula" />;
  }

  const userRole = currentOrg?.role;

  if (userRole && userRole === UserOrganisationRole.member) {
    return <Navigate to="/download-app" />;
  }

  if (
    (userRole && allowedRoles?.includes(userRole)) ||
    !allowedRoles ||
    allowedRoles!.length === 0
  ) {
    return <>{children}</>;
  }

  return <Navigate to="/organisation/choose" />;
}

export default UserGuard;
