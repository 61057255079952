import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../models/apiResponseMeta";
import axios from "../../../utils/axios";
import { OrganisationMember } from "../models/organisationMember";
import { GetOrganisationMembersRequest } from "../slices/usersOrganisationMembersSlice";

type GetOrganisationMembersResponse = {
  data: OrganisationMember[];
  meta: ApiResponseMeta;
};

async function getOrganisationMembers(
  request: GetOrganisationMembersRequest
): Promise<AxiosPromise<GetOrganisationMembersResponse>> {
  return await axios.post(`/orgs/v1/${request.orgId}/members/query`, request);
}

type DeleteOrganisationMemberResponse = {
  meta: ApiResponseMeta;
};

async function deleteOrganisationMember(
  orgId: string,
  userId: string
): Promise<AxiosPromise<DeleteOrganisationMemberResponse>> {
  return await axios.delete(`/orgs/v1/${orgId}/members/${userId}`);
}

type UpdateOrganisationMemberRequest = {
  jobTitle?: string;
  email?: string;
  active?: boolean;
};

async function updateOrganisationMember(
  orgId: string,
  userId: string,
  body: UpdateOrganisationMemberRequest
): Promise<AxiosPromise<DeleteOrganisationMemberResponse>> {
  return await axios.put(`/orgs/v1/${orgId}/members/${userId}`, body);
}

export {
  getOrganisationMembers,
  deleteOrganisationMember,
  updateOrganisationMember,
};
