import CanViewCapabilityNavItemGuard from "../components/sidebar/navItemGuards/CanViewNavItemGuard";
import VisiblyAdminNavItemGuard from "../components/sidebar/navItemGuards/VisiblyAdminNavItemGuard";
import { SidebarSectionType } from "../types/sidebar";
import { checkInsSection } from "./checkInsSection";
import { complianceSection } from "./compliance/complianceSection";
import { internalAdminSection } from "./internalAdminSection";
import { librariesSection } from "./libraries/librariesSection";

export const navSections = [
  {
    title: "Compliance",
    pages: complianceSection,
    wrapperElement: (key, children) => {
      return (
        <CanViewCapabilityNavItemGuard key={key} capability="accreditations">
          {children}
        </CanViewCapabilityNavItemGuard>
      );
    },
  },
  {
    title: "Libraries",
    pages: librariesSection,
    wrapperElement: (key, children) => {
      return (
        <CanViewCapabilityNavItemGuard key={key} capability="accreditations">
          {children}
        </CanViewCapabilityNavItemGuard>
      );
    },
  },
  {
    title: "Duty of care",
    pages: checkInsSection,
    wrapperElement: (key, children) => {
      return (
        <CanViewCapabilityNavItemGuard key={key} capability="checkIns">
          {children}
        </CanViewCapabilityNavItemGuard>
      );
    },
  },
  {
    title: "Internal admin",
    pages: internalAdminSection,
    wrapperElement: (key, children) => {
      return (
        <VisiblyAdminNavItemGuard key={key}>
          {children}
        </VisiblyAdminNavItemGuard>
      );
    },
  },
] as SidebarSectionType[];

export default navSections;
