import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../common/models/loadingStatus";
import {
  ImportedWorker,
  OrganisationMember,
} from "../models/organisationMember";
import * as organisationMembersService from "../services/organisationMembersService";
import { uploadFileToFileStorage } from "../../common/services/s3ClientStorageService";

export type QueryOrganisationMembersRequest = {
  orgId: string;
  includeUnSignedUp: boolean;
};

export const queryOrganisationMembers = createAsyncThunk(
  "organisationMember/queryOrganisationMembers",
  async (request: QueryOrganisationMembersRequest, { rejectWithValue }) => {
    try {
      return (
        await organisationMembersService.queryOrganisationMembers(request)
      ).data;
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

type ImportUserCsvRequest = {
  orgId: string;
  file: File;
};

export const importUsersCsv = createAsyncThunk(
  "organisationMembers/importUsersCsv",
  async (request: ImportUserCsvRequest, { rejectWithValue }) => {
    const importId = nanoid();

    try {
      await uploadFileToFileStorage(
        `public/${request.orgId}/uploads/${importId}.csv`,
        request.file,
        "text/csv"
      );
      return (
        await organisationMembersService.importUsers({
          orgId: request.orgId,
          csvImportId: importId,
        })
      ).data;
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

type OrganisationMemberState = {
  status: LoadingStatus;
  organisationMembers: OrganisationMember[];
  bulkImportResponse: ImportedWorker[];
  error: string | null;
};

const initialState: OrganisationMemberState = {
  status: LoadingStatus.idle,
  organisationMembers: [],
  bulkImportResponse: [],
  error: null,
};

const organisationMemberSlice = createSlice({
  name: "organisationMember",
  initialState,
  reducers: {
    cleanState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryOrganisationMembers.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(queryOrganisationMembers.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        organisationMembers: action.payload.data,
      };
    });
    builder.addCase(queryOrganisationMembers.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(importUsersCsv.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(importUsersCsv.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        bulkImportResponse: action.payload.data,
      };
    });
    builder.addCase(importUsersCsv.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export const { cleanState } = organisationMemberSlice.actions;
export default organisationMemberSlice.reducer;
