import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../../common/models/apiResponseMeta";
import axios from "../../../utils/axios";
import { PublicInvite } from "../models/publicInvite";

export type AcceptInviteResponse = {
  meta: ApiResponseMeta;
};

async function acceptInvite(
  inviteId: string,
  userId: string
): Promise<AxiosPromise<AcceptInviteResponse>> {
  const body = {
    inviteId: inviteId,
    userId: userId,
  };

  return await axios.post(`invites/v1/accept`, body);
}

export type GetPublicInviteResponse = {
  data: PublicInvite;
  meta: ApiResponseMeta;
};

async function getPublicInvite(
  id: string
): Promise<AxiosPromise<GetPublicInviteResponse>> {
  return await axios.get(`invites/v1/public/${id}`);
}

type ResendInviteRequest = {
  orgId: string;
  inviteId: string;
};

type ResendInviteResponse = {
  meta: ApiResponseMeta;
};

async function resendInvite(
  request: ResendInviteRequest
): Promise<AxiosPromise<ResendInviteResponse>> {
  const body = {
    orgId: request.orgId,
    inviteId: request.inviteId,
  };

  return await axios.post(`invites/v1/resend-invite`, body);
}

export { acceptInvite, getPublicInvite, resendInvite };
