import { useCurrentOrg } from "../../../hooks/userHooks";

interface CanViewNavItemGuardProps {
  children?: React.ReactNode;
  capability: string;
}

const CanViewCapabilityNavItemGuard = ({
  children,
  capability,
}: CanViewNavItemGuardProps) => {
  const currentOrg = useCurrentOrg();

  if (currentOrg?.capabilities?.find((e) => e === capability) === undefined) {
    return <></>;
  }

  return <>{children}</>;
};

export default CanViewCapabilityNavItemGuard;
