import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../../common/models/loadingStatus";
import * as rolesService from "../services/rolesService";
import { Role } from "../../../models/role";

export type QueryRolesRequest = {
  orgId: string;
};

export const queryRoles = createAsyncThunk(
  "roles/query",
  async (request: QueryRolesRequest, { rejectWithValue }) => {
    try {
      return (await rolesService.queryRoles(request)).data;
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

type RoleState = {
  status: LoadingStatus;
  roles: Role[];
  error: string | null;
};

const initialState: RoleState = {
  status: LoadingStatus.idle,
  roles: [],
  error: null,
};

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    cleanState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryRoles.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(queryRoles.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        roles: action.payload.data,
      };
    });
    builder.addCase(queryRoles.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export const { cleanState } = rolesSlice.actions;
export default rolesSlice.reducer;
