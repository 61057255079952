import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as accreditationUserService from "../services/accreditationUserService";
import { LoadingStatus } from "../../common/models/loadingStatus";
import { UserRoleCompliance } from "../models/accreditationUser";

export type QueryRoleComplianceRequest = {
  roleId: string;
  groupId: string;
  orgId: string;
};

export const queryRoleCompliance = createAsyncThunk(
  "accreditationUser/queryRoleCompliance",
  async (request: QueryRoleComplianceRequest, { rejectWithValue }) => {
    try {
      return (await accreditationUserService.queryRoleCompliance(request)).data;
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

type AccreditationUserState = {
  status: LoadingStatus;
  roleCompliances: UserRoleCompliance[];
  error: string | null;
};

const initialState: AccreditationUserState = {
  status: LoadingStatus.idle,
  roleCompliances: [],
  error: null,
};

const accreditationUserSlice = createSlice({
  name: "accreditationUser",
  initialState,
  reducers: {
    cleanState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryRoleCompliance.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(queryRoleCompliance.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        roleCompliances: action.payload.data,
      };
    });
    builder.addCase(queryRoleCompliance.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export const { cleanState } = accreditationUserSlice.actions;

export default accreditationUserSlice.reducer;
