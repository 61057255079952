import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../models/apiResponseMeta";
import axios from "../../../utils/axios";
import { AccreditationSpec } from "../models/accreditationSpec";
import { QueryRequest } from "../slices/accreditationSpecsSlice";

type QueryResponse = {
  data: AccreditationSpec[];
  meta: ApiResponseMeta;
};

export async function queryAccreditationSpecs(
  request: QueryRequest
): Promise<AxiosPromise<QueryResponse>> {
  return await axios.post(`accreditations/specs/v1/query`, request);
}

type GetResponse = {
  data: AccreditationSpec;
  meta: ApiResponseMeta;
};

export async function getAccreditationSpec(
  id: number
): Promise<AxiosPromise<GetResponse>> {
  return await axios.get(`accreditations/specs/v1/${id}`);
}
