import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingStatus } from "../models/loadingStatus";
import { OrganisationMember } from "../models/organisationMember";
import * as organisationMembersService from "../services/organisationMembersService";

export type GetOrganisationMembersRequest = {
  orgId: string;
  searchString?: string;
  active?: boolean;
  teamId?: string;
  roles?: string[];
  allUsers?: boolean;
  includeUnSignedUp?: boolean;
};

export const getOrganisationMembers = createAsyncThunk(
  "usersOrganisationMembers/getOrganisationMembers",
  async (request: GetOrganisationMembersRequest, { rejectWithValue }) => {
    try {
      const orgMembers = (
        await organisationMembersService.getOrganisationMembers(request)
      ).data;

      if (request.allUsers) {
        var remainingOrgMembers = (
          await organisationMembersService.getOrganisationMembers({
            ...request,
            active: request.active !== undefined ? !request.active : false,
          })
        ).data;

        const allOrgMembers = orgMembers.data.concat(remainingOrgMembers.data);

        return {
          data: allOrgMembers,
          meta: {},
        };
      } else {
        return orgMembers;
      }
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

interface OrganisationMemberState {
  organisationMembers: OrganisationMember[];
  status: LoadingStatus;
  error: string;
}

const initialState: OrganisationMemberState = {
  organisationMembers: [],
  status: LoadingStatus.idle,
  error: "",
};

const usersOrganisationMembersSlice = createSlice({
  name: "usersOrganisationMembers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganisationMembers.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getOrganisationMembers.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        organisationMembers: action.payload.data,
      };
    });

    builder.addCase(getOrganisationMembers.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        organisationMembers: [],
      };
    });
  },
});

export default usersOrganisationMembersSlice.reducer;
