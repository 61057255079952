import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../common/models/loadingStatus";
import {
  CheckInStatsRequest,
  getUserAlertsAsync,
  getAlertResolutionsAsync,
  getOverviewAsync,
  getUserActivityAsync,
} from "../services/checkInStatsService";
import {
  AlertResolution,
  CheckInStatsOverview,
  UserActivityStatsResponse,
  UserAlert,
} from "../models/checkInStats";

export const getCheckInStatsOverview = createAsyncThunk(
  "activities/getOverview",
  async (request: CheckInStatsRequest, { rejectWithValue }) => {
    try {
      const result = await getOverviewAsync(request);

      return result.data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

export const getUserAlerts = createAsyncThunk(
  "activities/getUserAlerts",
  async (request: CheckInStatsRequest, { rejectWithValue }) => {
    try {
      const result = await getUserAlertsAsync(request);

      return result.data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

export const getAlertResolutions = createAsyncThunk(
  "activities/getAlertResolutions",
  async (request: CheckInStatsRequest, { rejectWithValue }) => {
    try {
      const result = await getAlertResolutionsAsync(request);

      return result.data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

export const getUserActivity = createAsyncThunk(
  "activities/getUserActivity",
  async (request: CheckInStatsRequest, { rejectWithValue }) => {
    try {
      const result = await getUserActivityAsync(request);

      return result.data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

interface CheckInStatsState {
  userAlerts: UserAlert[];
  alertResolutions: AlertResolution[];
  userActivity: UserActivityStatsResponse;
  checkInStatsOverview?: CheckInStatsOverview;
  userAlertsStatus: LoadingStatus;
  alertResolutionsStatus: LoadingStatus;
  checkInStatsOverviewStatus: LoadingStatus;
  userActivityStatus: LoadingStatus;
  error: string;
}

const initialState: CheckInStatsState = {
  userAlerts: [],
  alertResolutions: [],
  userActivity: {
    activities: 0,
    userCount: 0,
    userActivities: [],
  },
  userAlertsStatus: LoadingStatus.idle,
  alertResolutionsStatus: LoadingStatus.idle,
  checkInStatsOverviewStatus: LoadingStatus.idle,
  userActivityStatus: LoadingStatus.idle,
  error: "",
};

const checkInStatsSlice = createSlice({
  name: "checkInStats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserAlerts.pending, (state, action) => {
      return { ...state, userAlertsStatus: LoadingStatus.loading };
    });
    builder.addCase(getUserAlerts.fulfilled, (state, action) => {
      return {
        ...state,
        userAlertsStatus: LoadingStatus.succeeded,
        userAlerts: action.payload.data,
      };
    });
    builder.addCase(getUserAlerts.rejected, (state, action) => {
      return {
        ...state,
        userAlertsStatus: LoadingStatus.failed,
        userAlerts: [],
      };
    });

    builder.addCase(getAlertResolutions.pending, (state, action) => {
      return { ...state, alertResolutionsStatus: LoadingStatus.loading };
    });
    builder.addCase(getAlertResolutions.fulfilled, (state, action) => {
      return {
        ...state,
        alertResolutionsStatus: LoadingStatus.succeeded,
        alertResolutions: action.payload.data,
      };
    });
    builder.addCase(getAlertResolutions.rejected, (state, action) => {
      return {
        ...state,
        alertResolutionsStatus: LoadingStatus.failed,
      };
    });

    builder.addCase(getCheckInStatsOverview.pending, (state, action) => {
      return { ...state, alertResolutionsStatus: LoadingStatus.loading };
    });
    builder.addCase(getCheckInStatsOverview.fulfilled, (state, action) => {
      return {
        ...state,
        checkInStatsOverviewStatus: LoadingStatus.succeeded,
        checkInStatsOverview: action.payload.data,
      };
    });
    builder.addCase(getCheckInStatsOverview.rejected, (state, action) => {
      return {
        ...state,
        checkInStatsOverviewStatus: LoadingStatus.failed,
      };
    });

    builder.addCase(getUserActivity.pending, (state, action) => {
      return { ...state, userActivityStatus: LoadingStatus.loading };
    });
    builder.addCase(getUserActivity.fulfilled, (state, action) => {
      return {
        ...state,
        userActivityStatus: LoadingStatus.succeeded,
        userActivity: action.payload.data,
      };
    });
    builder.addCase(getUserActivity.rejected, (state, action) => {
      return {
        ...state,
        userActivityStatus: LoadingStatus.failed,
      };
    });
  },
});

export default checkInStatsSlice.reducer;
