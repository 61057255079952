import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../../common/models/apiResponseMeta";
import {
  ImportedWorker,
  OrganisationMember,
} from "../models/organisationMember";
import { QueryOrganisationMembersRequest } from "../slices/organisationMembersSlice";
import axios from "../../../utils/axios";

type QueryOrganisationMembersResponse = {
  data: OrganisationMember[];
  meta: ApiResponseMeta;
};

export async function queryOrganisationMembers(
  request: QueryOrganisationMembersRequest
): Promise<AxiosPromise<QueryOrganisationMembersResponse>> {
  return await axios.post(`/orgs/v1/${request.orgId}/members/query`, request);
}

type CreateWorkerResponse = {
  meta: ApiResponseMeta;
};

type ManageWorkerRequest = {
  orgId: string;
  firstNames: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  postcode?: string;
  dateOfBirth?: Date;
};

export async function createWorker(
  request: ManageWorkerRequest
): Promise<AxiosPromise<CreateWorkerResponse>> {
  return await axios.post(`/orgs/v1/${request.orgId}/members`, request);
}

export async function updateWorker(
  id: string,
  request: ManageWorkerRequest
): Promise<AxiosPromise<CreateWorkerResponse>> {
  return await axios.put(`/orgs/v1/${request.orgId}/members/${id}`, request);
}

type BulkImportRequest = {
  csvImportId: string;
  orgId: string;
};

type BulkImportResponse = {
  meta: ApiResponseMeta;
  data: ImportedWorker[];
};

export async function importUsers(
  request: BulkImportRequest
): Promise<AxiosPromise<BulkImportResponse>> {
  return await axios.post(
    `/orgs/v1/${request.orgId}/members/import-from-csv`,
    request
  );
}
