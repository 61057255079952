import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as groupRolesService from "../services/groupRolesService";
import { LoadingStatus } from "../../../../common/models/loadingStatus";
import { GroupRole } from "../models/groupRoles";
import { UnassignDto } from "../../../models/assignedRoles";

export type QueryGroupRolesRequest = {
  orgId: string;
};

export const queryGroupRoles = createAsyncThunk(
  "groups/query-roles",
  async (
    { groupId, ...request }: QueryGroupRolesRequest & { groupId: string },
    { rejectWithValue }
  ) => {
    try {
      return (await groupRolesService.queryGroupRoles(groupId, request)).data;
    } catch (err: any) {
      return rejectWithValue({
        error: err.meta.errorMessage ?? "An error occurred",
      });
    }
  }
);

export type AssignRequest = {
  orgId: string;
  userIds: string[];
};

export const bulkAssign = createAsyncThunk(
  "groups/bulk-assign",
  async (
    {
      groupId,
      roleId,
      ...request
    }: AssignRequest & { groupId: string; roleId: string },
    { rejectWithValue }
  ) => {
    try {
      return (await groupRolesService.bulkAssign(groupId, roleId, request))
        .data;
    } catch (err: any) {
      return rejectWithValue({
        error: err.meta.errorMessage ?? "An error occurred",
      });
    }
  }
);

type BulkUnassignRequest = {
  orgId: string;
  rolesToUnassign: UnassignDto[];
};

export const bulkUnassign = createAsyncThunk(
  "groups/bulk-unassign",
  async (
    { groupId, ...request }: BulkUnassignRequest & { groupId: string },
    { rejectWithValue }
  ) => {
    try {
      await Promise.all(
        request.rolesToUnassign.map(async (el) => {
          await groupRolesService.unassign(groupId, el.roleId, {
            orgId: request.orgId,
            userIds: el.userIds,
          });
        })
      );
      return {
        meta: {},
      };
    } catch (err: any) {
      return rejectWithValue({
        error: err.meta.errorMessage ?? "An error occurred",
      });
    }
  }
);

export const unassign = createAsyncThunk(
  "groups/unassign",
  async (
    {
      groupId,
      roleId,
      ...request
    }: AssignRequest & { groupId: string; roleId: string },
    { rejectWithValue }
  ) => {
    try {
      return (await groupRolesService.unassign(groupId, roleId, request)).data;
    } catch (err: any) {
      return rejectWithValue({
        error: err.meta.errorMessage ?? "An error occurred",
      });
    }
  }
);

type GroupRoleState = {
  status: LoadingStatus;
  unassignStatus: LoadingStatus;
  groupRoles: GroupRole[];
  error?: string;
};

const initialState: GroupRoleState = {
  status: LoadingStatus.idle,
  unassignStatus: LoadingStatus.idle,
  groupRoles: [],
};

const groupRolesSlice = createSlice({
  name: "groupRoles",
  initialState,
  reducers: {
    cleanState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryGroupRoles.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(queryGroupRoles.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        groupRoles: action.payload.data,
      };
    });
    builder.addCase(queryGroupRoles.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(bulkAssign.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(bulkAssign.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
      };
    });
    builder.addCase(bulkAssign.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: action.error.message,
      };
    });
    builder.addCase(bulkUnassign.pending, (state, action) => {
      return { ...state, unassignStatus: LoadingStatus.loading };
    });
    builder.addCase(bulkUnassign.fulfilled, (state, action) => {
      return {
        ...state,
        unassignStatus: LoadingStatus.succeeded,
      };
    });
    builder.addCase(bulkUnassign.rejected, (state, action) => {
      return {
        ...state,
        unassignStatus: LoadingStatus.failed,
        error: action.error.message,
      };
    });
    builder.addCase(unassign.pending, (state, action) => {
      return { ...state, unassignStatus: LoadingStatus.loading };
    });
    builder.addCase(unassign.fulfilled, (state, action) => {
      return {
        ...state,
        unassignStatus: LoadingStatus.succeeded,
      };
    });
    builder.addCase(unassign.rejected, (state, action) => {
      return {
        ...state,
        unassignStatus: LoadingStatus.failed,
        error: action.error.message,
      };
    });
  },
});

export const { cleanState } = groupRolesSlice.actions;
export default groupRolesSlice.reducer;
