import VisiblyAdminGuard from "../components/guards/VisiblyAdminGuard";
import VisiblyOSLayout from "../components/layout/VisiblyOSLayout";
import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const CreateInvite = lazy(
  () => import("../features/internalAdmin/pages/CreateInvite")
);
const InternalCreateOrganisation = lazy(
  () => import("../features/internalAdmin/pages/CreateOrganisation")
);

export const internalAdminRoutes = [
  {
    path: "internal-admin",
    element: (
      <VisiblyAdminGuard>
        <VisiblyOSLayout />
      </VisiblyAdminGuard>
    ),
    children: [
      {
        path: "create-organisation",
        element: <InternalCreateOrganisation />,
      },
      {
        path: "invite-user",
        element: <CreateInvite />,
      },
    ],
  },
] as RouteObject[];
