import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../models/loadingStatus";
import { Team } from "../models/teams";
import { GetTeamsRequest } from "../services/teamsService";
import * as teamsProvider from "../services/teamsService";

export const getTeams = createAsyncThunk(
  "teams/getTeams",
  async (
    { orgId, name, capabilities }: GetTeamsRequest,
    { rejectWithValue }
  ) => {
    try {
      return (
        await teamsProvider.getTeams({
          orgId: orgId,
          name: name,
          capabilities: capabilities,
        })
      ).data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

export const getTeam = createAsyncThunk(
  "teams/getTeam",
  async (id: string, { rejectWithValue }) => {
    try {
      return (await teamsProvider.getTeamById(id)).data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

interface TeamState {
  teams: Team[];
  team: Team;
  status: LoadingStatus;
  error: string;
}

const initialState: TeamState = {
  teams: [],
  team: {} as Team,
  status: LoadingStatus.idle,
  error: "",
};

const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    clearCurrentTeam: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTeams.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(getTeams.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        teams: action.payload.data,
      };
    });
    builder.addCase(getTeams.rejected, (state, action) => {
      return { ...state, status: LoadingStatus.failed, teams: [] };
    });
    builder.addCase(getTeam.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(getTeam.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        teams: state.teams,
        team: action.payload.data,
      };
    });
    builder.addCase(getTeam.rejected, (state, action) => {
      return { ...state, status: LoadingStatus.failed };
    });
  },
});

export const { clearCurrentTeam } = teamsSlice.actions;

export default teamsSlice.reducer;
