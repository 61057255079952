import axios, { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../../common/models/apiResponseMeta";
import { EnvironmentDetails } from "../models/environment";
import { visiblyApiConfig } from "../../../config";

const axiosInstance = axios.create({
  baseURL: visiblyApiConfig.baseUrl,
});

type GetEnvironmentDetailsResponse = {
  data: EnvironmentDetails;
  meta: ApiResponseMeta;
};

async function getEnvironmentDetailsAsync(): Promise<
  AxiosPromise<GetEnvironmentDetailsResponse>
> {
  return await axiosInstance.get("environments/v1");
}

export { getEnvironmentDetailsAsync };
