import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../common/models/loadingStatus";
import { AccreditationSpec } from "../models/accreditationSpec";
import * as accreditationSpecsService from "../services/accreditationSpecsService";

export type QueryRequest = {
  orgId: string;
};

export const queryAccreditationSpecs = createAsyncThunk(
  "accreditationSpecs/query",
  async (request: QueryRequest, { rejectWithValue }) => {
    try {
      return (await accreditationSpecsService.queryAccreditationSpecs(request))
        .data;
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

export const getAccreditationSpec = createAsyncThunk(
  "accreditationSpecs/get",
  async (id: number, { getState, rejectWithValue }) => {
    try {
      const state = (
        getState() as { accreditationSpecs: accreditationSpecsState }
      ).accreditationSpecs;

      const accreditationSpec = state.accreditationSpecs.find(
        (accreditationSpec) => accreditationSpec.id === id
      );

      return (
        accreditationSpec ??
        (await accreditationSpecsService.getAccreditationSpec(id)).data.data
      );
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

type accreditationSpecsState = {
  status: LoadingStatus;
  accreditationSpecs: AccreditationSpec[];
  accreditationSpec?: AccreditationSpec;
  error: string | null;
};

const initialState: accreditationSpecsState = {
  status: LoadingStatus.idle,
  accreditationSpecs: [],
  error: null,
};

const accreditationSpecsSlice = createSlice({
  name: "accreditationSpecs",
  initialState,
  reducers: {
    cleanState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryAccreditationSpecs.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(queryAccreditationSpecs.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        accreditationSpecs: action.payload.data,
      };
    });
    builder.addCase(queryAccreditationSpecs.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(getAccreditationSpec.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(getAccreditationSpec.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        accreditationSpec: action.payload,
      };
    });
    builder.addCase(getAccreditationSpec.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export const { cleanState } = accreditationSpecsSlice.actions;
export default accreditationSpecsSlice.reducer;
