import { Navigate, RouteObject, useParams } from "react-router-dom";
import VisiblyOSLayout from "../components/layout/VisiblyOSLayout";
import { lazy, ReactElement, useContext, useEffect } from "react";
import CanViewCapabilityRouteGuard from "../components/guards/CanViewCapabilityRouteGuard";
import AccreditationDetails from "../features/libraries/pages/accreditations/AccreditationDetails";
import useAppDispatch from "../hooks/useAppDispatch";
import { PageHeaderContext } from "../contexts/PageHeaderContext";
import { getAccreditationSpec } from "../features/common/slices/accreditationSpecsSlice";

const AccreditationLibrary = lazy(
  () => import("../features/libraries/pages/accreditations")
);

export const librariesRoutes = [
  {
    path: "libraries",
    element: (
      <CanViewCapabilityRouteGuard capability="accreditations">
        <VisiblyOSLayout />
      </CanViewCapabilityRouteGuard>
    ),
    children: [
      {
        path: "accreditations",
        element: <AccreditationLibrary />,
      },
      {
        path: "accreditations/:accreditationSpecId",
        children: [
          {
            path: "",
            element: <NavigateToAccreditationSpecHome />,
          },
          {
            path: "details",
            element: (
              <AccreditationSpecWrapper>
                <AccreditationDetails />
              </AccreditationSpecWrapper>
            ),
          },
        ],
      },
    ],
  },
] as RouteObject[];

type AccreditationSpecWrapperProps = {
  children: ReactElement;
};

function AccreditationSpecWrapper(props: AccreditationSpecWrapperProps) {
  const dispatch = useAppDispatch();
  const { accreditationSpecId } = useParams();
  const { accreditationSpec, setAccreditationSpec } =
    useContext(PageHeaderContext);

  useEffect(() => {
    if (
      accreditationSpecId &&
      (!accreditationSpec ||
        Number(accreditationSpecId) !== accreditationSpec.id)
    ) {
      dispatch(getAccreditationSpec(Number(accreditationSpecId)))
        .unwrap()
        .then((value) => {
          setAccreditationSpec!(value);
        });
    }
  }, [accreditationSpec, accreditationSpecId, setAccreditationSpec, dispatch]);

  return (
    <>
      {accreditationSpec && Number(accreditationSpecId) === accreditationSpec.id
        ? props.children
        : null}
    </>
  );
}

function NavigateToAccreditationSpecHome() {
  const { accreditationSpecId } = useParams();

  return (
    <Navigate to={`/libraries/accreditations/${accreditationSpecId}/details`} />
  );
}
