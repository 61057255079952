import { Users } from "react-feather";
import { SidebarItemsType } from "../../types/sidebar";

export const groupsItem = {
  href: "/compliance/groups",
  icon: Users,
  pageTitle: () => "Groups",
  innerNavigationItems: [
    {
      title: "Dashboard",
      to: (_) => "",
      disabled: true,
    },
    {
      title: "Groups",
      to: (_) => "/compliance/groups",
    },
  ],
  children: [
    {
      pageTitle: (groupName: string) => `${groupName ?? ""}`,
      hasBackButton: true,
      backButtonTo: (_) => `/compliance/groups`,
      type: "group",
      innerNavigationItems: [
        {
          title: "Dashboard",
          to: (_) => ``,
          disabled: true,
        },
        {
          title: "Group roles",
          to: ({ groupId }) => `/compliance/groups/${groupId}/group-roles`,
        },
        {
          title: "Contractors",
          to: (_) => ``,
          disabled: true,
        },
        {
          title: "Workers",
          to: (_) => ``,
          disabled: true,
        },
        {
          title: "Worker roles",
          to: ({ groupId }) => `/compliance/groups/${groupId}/worker-roles`,
        },
        {
          title: "Accreditations",
          to: (_) => ``,
          disabled: true,
        },
      ],
    },
  ],
} as SidebarItemsType;
