import { ReactNode, useRef } from "react";
import useAuth from "../../../../hooks/useAuth";
import { useCurrentOrg, useCurrentUser } from "../../../../hooks/userHooks";
import { Helmet } from "react-helmet-async";
import { Brand, Wrapper } from "../../../../components/layout/Common";
import { Button, Stack, Typography } from "@mui/material";
import LogoFormLayout from "../../../../layouts/LogoForm";
import { getTargetOrgIdFromLocation } from "../../utils/orgTargeting";
import useAppSelector from "../../../../hooks/useAppSelector";
import { useNavigate, useLocation } from "react-router-dom";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import {
  clearAppEntryLocation,
  selectAppEntryLocation,
} from "../../../common/slices/userSlice";

interface AppEntryOrganisationMismatchPrompterProps {
  children: ReactNode;
}

const AppEntryOrganisationMismatchPrompter = ({
  children,
}: AppEntryOrganisationMismatchPrompterProps) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const currentOrg = useCurrentOrg();
  const currentUser = useCurrentUser();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const appEntryLocation = useAppSelector(selectAppEntryLocation);
  const willRaisePrompt = useRef(true);

  if (!willRaisePrompt.current || !isAuthenticated || !appEntryLocation) {
    return <>{children}</>;
  }

  const targetOrgId = getTargetOrgIdFromLocation(appEntryLocation);
  if (!targetOrgId || targetOrgId === currentOrg?.id) {
    willRaisePrompt.current = false;
    if (appEntryLocation === location) {
      dispatch(clearAppEntryLocation());
    }

    return <>{children}</>;
  }

  const storeResponseAndNavigate = (path: string) => {
    willRaisePrompt.current = false;
    navigate(path);
  };

  const onSwitchOrganisationClick = () => {
    storeResponseAndNavigate("/organisation/choose");
  };

  const onContinueClick = () => {
    storeResponseAndNavigate("/");
  };

  const targetOrg = currentUser?.orgs?.find((org) => org.id === targetOrgId);
  if (!targetOrg || !currentOrg) {
    return <>{children}</>;
  }

  return (
    <LogoFormLayout>
      <Helmet title="Change Organisation?" />
      <Brand />
      <Wrapper>
        <Stack spacing={5}>
          <Typography textAlign={"center"} fontSize={24} fontWeight={"bold"}>
            Change Organisation?
          </Typography>

          <Typography textAlign={"center"}>
            You visited to view information concerning
          </Typography>

          <Typography textAlign={"center"} fontWeight={"bold"} fontSize={16}>
            {targetOrg.name}
          </Typography>

          <Typography textAlign={"center"}>
            but are currently viewing
          </Typography>

          <Typography textAlign={"center"} fontWeight={"bold"} fontSize={16}>
            {currentOrg.name}
          </Typography>

          <Button
            variant="contained"
            fullWidth
            onClick={onSwitchOrganisationClick}
          >
            Switch to {targetOrg.name}
          </Button>

          <Button variant="outlined" fullWidth onClick={onContinueClick}>
            Continue to {currentOrg.name}
          </Button>
        </Stack>
      </Wrapper>
    </LogoFormLayout>
  );
};

export default AppEntryOrganisationMismatchPrompter;
