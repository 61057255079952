import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../../common/models/apiResponseMeta";
import { AssignedRole } from "../models/assignedRoles";
import axios from "../../../utils/axios";
import { QueryAssignedRequest } from "../slices/accreditationRolesSlice";

type QueryAssignedResponse = {
  data: AssignedRole[];
  meta: ApiResponseMeta;
};

export async function queryAssigned(
  request: QueryAssignedRequest
): Promise<AxiosPromise<QueryAssignedResponse>> {
  return await axios.post(`accreditations/roles/v1/query-assigned`, request);
}
