import { Grid } from "@mui/material";
import { ColDef } from "ag-grid-community";
import { useContext } from "react";
import NewAgGridTable from "../../../../components/NewAgGridTable";
import { PageHeaderContext } from "../../../../contexts/PageHeaderContext";

export default function AccreditationDetails() {
  const { accreditationSpec } = useContext(PageHeaderContext);

  let columns: ColDef[] = [
    {
      field: "name",
      headerName: "Accreditation",
      sortable: true,
      flex: 1,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "block",
      },
    },
    {
      field: "code",
      headerName: "Code",
      sortable: true,
      flex: 0.5,
    },
    {
      field: "orgName",
      headerName: "Owner",
      sortable: true,
      flex: 0.5,
    },
  ];
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} height={200}>
        <NewAgGridTable
          data={[accreditationSpec]}
          columns={columns}
          dataCyTag="accreditationSpecTable"
        />
      </Grid>
    </Grid>
  );
}
