import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as accreditationRolesService from "../services/accreditationRolesService";
import { LoadingStatus } from "../../common/models/loadingStatus";
import { AssignedRole } from "../models/assignedRoles";

export type QueryAssignedRequest = {
  groupId?: string;
  orgId: string;
  userId?: string;
  roleIds?: string[];
};

export const queryAssigned = createAsyncThunk(
  "accreditationRoles/queryAssignedRoles",
  async (request: QueryAssignedRequest, { rejectWithValue }) => {
    try {
      return (await accreditationRolesService.queryAssigned(request)).data;
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

type AccreditationRolesState = {
  status: LoadingStatus;
  assignedRoles: AssignedRole[];
  error: string | null;
};

const initialState: AccreditationRolesState = {
  status: LoadingStatus.idle,
  assignedRoles: [],
  error: null,
};

const accreditationRolesSlice = createSlice({
  name: "accreditationRoles",
  initialState,
  reducers: {
    cleanState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryAssigned.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(queryAssigned.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        assignedRoles: action.payload.data,
      };
    });
    builder.addCase(queryAssigned.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export const { cleanState } = accreditationRolesSlice.actions;
export default accreditationRolesSlice.reducer;
