import React from "react";
import styled from "@emotion/styled";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import { useCurrentUser } from "../../hooks/userHooks";
import useAppSelector from "../../hooks/useAppSelector";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const user = useCurrentUser();
  const environmentDetails = useAppSelector(
    (state) => state.environment.environmentDetails
  );

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/");
  };

  const handleUserAgreement = async () => {
    window.open(environmentDetails?.eula?.url, "_blank");
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {user && user?.orgs.length > 1 ? (
          <MenuItem
            onClick={() =>
              navigate("/organisation/choose?allowAutoOrgSelect=0")
            }
            data-cy="chooseOrganisationButton"
          >
            Choose Organisation
          </MenuItem>
        ) : null}
        {(environmentDetails?.eula?.url ?? false) && (
          <MenuItem onClick={handleUserAgreement} data-cy="userAgreementButton">
            User agreement
          </MenuItem>
        )}
        <MenuItem onClick={handleSignOut} data-cy="signOutButton">
          Sign out
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
